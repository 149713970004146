import { translatedField } from '../util'

export const renderSearchResults = (searchResults, jobDetailsBaseUrl) => {
  const resultsContainer = document.querySelector('.joblist__list')

  if (resultsContainer) {
    const jobs = searchResults.map((job) => {
      const locations = job.locations.map((loc) => {
        if (loc && loc.city && loc.country) {
          const locCity = translatedField(loc.city)
          const locCountry = translatedField(loc.country)
          return `<span>${locCity}, ${locCountry}</span>`
        }
      })
      const jobTitle = translatedField(job.title)
      const jobType = translatedField(job.jobType)
      return `<div class="joblist__item">
              <h3><a href="${jobDetailsBaseUrl + '/' + job.jobId}">${jobTitle}</a></h3>
              <p>${locations.join('; ')} | ${jobType}</p>
            </div>`
    })

    resultsContainer.innerHTML = jobs.join('')
  }
}
